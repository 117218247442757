import React from 'react'

export default function Header() {
    
    return (
        <nav className="navbar">
            <div className="navbar-div">
            </div>
        </nav>
    )
}
